import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Index from './pages/Index'
import Documentation from './pages/Documentation'
import About from './pages/About'
import NotFound from './pages/NotFound'

class RouterComponent extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Index} />
          <Route path="/docs" component={Documentation} />
          <Route path="/about" component={About} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    )
  }
}

export default RouterComponent
