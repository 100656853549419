import React from 'react'
import Layout from '../components/Layout'

const NotFound = () => (
  <Layout title="not found">
    <strong>Error 404</strong>
    <p>Page not found.</p>
  </Layout>
)

export default NotFound
