import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import styles from './Layout.module.scss'

class Layout extends React.PureComponent {
  render() {
    const { children, title } = this.props
    return (
      <div className={styles.Layout}>
        <Helmet>
          <title>
            {title ? `${title} | planck` : 'planck — next-gen Supreme bot'}
          </title>
          <meta
            property="og:title"
            content={
              title ? `${title} | planck` : 'planck — next-gen Supreme bot'
            }
          />
          <meta property="description" content="" />
          <meta property="og:description" content="" />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://planck.software/meta-1500.png"
          />
          <meta property="og:url" content="https://planck.software" />
        </Helmet>
        <div className={styles.PageContent}>
          <div className={styles.Nav}>
            <Link to="/" className={styles.Home}>
              planck
            </Link>
          </div>
          {children}
        </div>
      </div>
    )
  }
}

export default Layout
